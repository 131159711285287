import styled from '@emotion/styled'
import { BetPane } from 'assets/images';
import { mixins } from 'theme';

export const PayoutContentContainer = styled.div`
  height: 450px;
  width: 370px;
  background: url(${BetPane});
  background-size: 100% 100%;
  color: white !important;
  padding: 20px;
  font-size: 12px;
  .paytable-row {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .row-left {
      flex: 2;
    }
    .row-right {
      flex: 1;
    }
  }
  @media (max-width: ${mixins.mobileWidth}px) {
    width: ${(props) => (props.isIn === "ins" ? 300 : 370)}px;
  }
`;